import * as templatesCache from "@common/templatesCache";



export default class PageMessage {
	#namespace = {
		selector: '#page-msg',
		templatePath: 'page.message', // templates/page.message.php
	};
	#timeout;
	#minShowTime = 3000;
	#isShow = false;
	#typesList = ['success', 'warning', 'danger'];


	constructor() {

	}

	/*--------------------------------------------------------------

	---------------------------------------------------------------*/
	#init = () => {
		if (!this.container) {
			this.container = document.querySelector(this.#namespace.selector);

			if (this.container) {
				this.msg = this.container.querySelector('.msg');

				this.#eventsInit();
			}
		}
	};

	#eventsInit(){
		this.container.querySelectorAll('.close-btn').forEach(btn => {
		    btn.addEventListener('click', () => {
				clearTimeout(this.#timeout);
				this.hide();
		    })
		})
	}

	hide (){
		if (this.container) {
			this.container.classList.remove('show');
			// this.container.classList.remove('show', '_success', '_warning', '_danger');

			this.#isShow = false;
		}
	}

	show(msg, type) {
		templatesCache.CGS(this.#namespace.templatePath)
			.then(() => {
				this.#init();

				this.#showMsg(msg, type);
			});
	}

	/*--------------------------------------------------------------

	---------------------------------------------------------------*/
	#showMsg = async (msg, type) => {
		if (this.#isShow) {
			if (String(msg).trim() !== this.msg.innerHTML) {
				this.hide();

				setTimeout(() => {
					this.show(msg, type);
				},  250);
			}
		}
		else {
			msg = String(msg).trim();

			if (msg.length) {
				this.msg.innerHTML = msg;

				this.#typesList.forEach(type => {
					this.container.classList.remove(`_${type}`);
				});

				if (this.#typesList.includes(type)) {
					this.container.classList.add(`_${type}`);
				}

				/*--------------------------------------------------------------
				Небольшая задержка для плавности процесса появления
				--------------------------------------------------------------*/
				await new Promise(_r => setTimeout(_r, 50));

				this.container.classList.add('show');

				this.#isShow = true;

				clearTimeout(this.#timeout);

				this.#timeout = setTimeout(() => {
					this.hide();
				},  msg.length * 100 > this.#minShowTime ? msg.length * 100 : this.#minShowTime);
			}
		}
	};
}