/*--------------------------------------------------------------
Импорт шрифтов
---------------------------------------------------------------*/
import '@stylesAssets/fonts/Raleway/Raleway.scss';
import '@stylesAssets/fonts/OpenSans/OpenSans.scss';

/*--------------------------------------------------------------
Генерация шрифта с иконками из SVG
---------------------------------------------------------------*/
import '@stylesAssets/webFontIcons/configuration';

/*--------------------------------------------------------------
Импорт стилей
--------------------------------------------------------------*/
import '@scss/main.scss';

/*--------------------------------------------------------------
Импорт модулей
--------------------------------------------------------------*/
import * as Template from '@common/template';
import AppStorage from '@common/AppStorage';
import PageMessage from "@components/PageMessage";



class Main {
	constructor() {
		this.#init();
	}

	#init = () => {
		/*--------------------------------------------------------------

		--------------------------------------------------------------*/
		Template.setTemplatesList();

		/*--------------------------------------------------------------
		Установка глобального экземпляра класса PageMessage
		---------------------------------------------------------------*/
		AppStorage.set('pageMessage', new PageMessage());
	};


}


document.addEventListener('DOMContentLoaded', function(){
	new Main();
});